import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardDworekSkruda = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/halinow/dworek-skruda/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/halinow/dworek-skruda-w-halinowie'}
      title={'Dworek Skruda w Halinowie'}
    >
      Majątek Skruda powstał w 1630 roku. W jego skład wchodziły dobra ziemskie
      w Długiej Kościelnej, stawy rybne oraz dwory. Majątkami zarządzali
      administratorzy. Zapewne pod koniec XIX lub na począdku XX wieku
      właściciele majątku Skruda zbudowali dworek.
    </MonumentCard>
  )
}

export default MonumentCardDworekSkruda
