import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardKrzyzOkuniewZabraniec = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/halinow/krzyz-okuniew-zabraniec/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/halinow/krzyz-pamiatkowy-miedzy-okuniewem-a-zabrancem'}
      title={'Okuniew-Zabraniec, Krzyż Pamiątkowy Między Okuniewem a Zabrańcem'}
    >
      W 1929 roku państwo Jan i Ewa Strzelec na wydmie znajdującej się na północ
      od Okuniewa, przy drodze do wsi Zabraniec ustawili pamiątkowy krzyż.
      Upamiętnia od walki w bolszewikami w 1920 roku, które toczyły się w tej
      okolicy, o czym świadczą pozostałe do dziś ślady okopów.
    </MonumentCard>
  )
}

export default MonumentCardKrzyzOkuniewZabraniec
