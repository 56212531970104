import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardObeliskOkuniew = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/halinow/okuniew-obelisk/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/halinow/mogila-zolnierska-z-1920-roku-w-okuniewie'}
      title={'Mogiła żołnierska z 1920 roku w Okuniewie'}
    >
      Na okuniewskim cmentarzu parafialnym znajduje się zbiorowa mogiła kilku
      żołnierzy wojny polsko-bolszewickiej. W połowie sierpnia 1920 roku w
      okolicach Okuniewa prowadzone były różnorakie działania związane z
      trwającą Bitwą Warszawską. Polegli oraz zmarli w wyniku ran odniesionych
      podczas działań mających miejsce w dniach od 13 do 18 sierpnia 1920 roku.
    </MonumentCard>
  )
}

export default MonumentCardObeliskOkuniew
