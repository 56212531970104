import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardKrzyzOkuniewZabraniec from '../../../monuments/halinow/MonumentCardKrzyzOkuniewZabraniec'
import MonumentCardDworekSkruda from '../../../monuments/halinow/MonumentCardDworekSkruda'
import MonumentCardObeliskOkuniew from '../../../monuments/halinow/MonumentCardObeliskOkuniew'

const HalinowArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Halinów" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Halinów</Header1>
        <MonumentCardKrzyzOkuniewZabraniec />
        <MonumentCardDworekSkruda />
        <MonumentCardObeliskOkuniew />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default HalinowArchive
